import { SuffixHeaderType } from '@/components/Table4/types';
import { PresetCriterias, ScreenerType } from '@/features/api/screener/screenerTypes';
import { VisibilityState } from '@tanstack/react-table';

export const energyLabelsData = [
  { value: 'Label Minergie', label: 'Label Minergie' },
  { value: 'Label Minergie-ECO', label: 'Label Minergie-ECO' },
  { value: 'Label Minergie-A', label: 'Label Minergie-A' },
  { value: 'Label Minergie-A-ECO', label: 'Label Minergie-A-ECO' },
  { value: 'Label Minergie-P', label: 'Label Minergie-P' },
  { value: 'Label Minergie-P-ECO', label: 'Label Minergie-P-ECO' },
];

export const typeData = ['mixed', 'residential', 'commercial'];
export const fundLegalFormData = ['foundation', 'listed_fund', 'unlisted_fund', 'real_estate_firm'];

export const lotHeaders: SuffixHeaderType[] = [
  // Default order
  { label: 'egrid' },
  { label: 'lot_nb', accessKey: 'nb' },
  { label: 'sector_nb' },
  { ascFirst: true, label: 'municipality_name' },

  // ENDOF Default order
  { label: 'area', suffix: 'units.area' },
  { label: 'buildings_nb' },
  { ascFirst: true, label: 'canton' },
  { ascFirst: true, label: 'region' },
];

export const buildingHeaders: SuffixHeaderType[] = [
  // Default order
  { ascFirst: true, label: 'street_short' },
  { ascFirst: true, label: 'locality' },
  { label: 'egid' },
  // ENDOF Default order

  { label: 'zip' },
  { ascFirst: true, label: 'type' },
  { ascFirst: true, label: 'building_category' },
  { label: 'construction_date' },
  { label: 'floors_nb' },
  { label: 'dwellings_nb' },
  { label: 'residential_area', suffix: 'units.area' },
  { label: 'commercial_area', suffix: 'units.area' },
  { label: 'total_area', suffix: 'units.area' },
  { label: 'potential_market_value', suffix: 'units.market_value' },
  { label: 'potential_net_rent', suffix: 'units.potential_net_rent' },
  { label: 'potential_roa', suffix: 'units.roa' },
  { label: 'estimated_co2_emission_per_m2', suffix: 'units.co2_m2' },

  { ascFirst: true, label: 'region' },
  { ascFirst: true, label: 'canton' },
  { ascFirst: true, label: 'fund_names' },
  { ascFirst: true, label: 'fund_legal_forms' },
  { label: 'ground_area', suffix: 'units.area' },
  {
    label: 'potential_market_value_per_m2',
    suffix: 'units.market_value_per_m2',
  },
  // {label: 'real_market_value', suffix: 'units.market_value' },
  { label: 'real_net_rent', suffix: 'units.real_net_rent' },
  { label: 'potential_net_rent_per_m2', suffix: 'units.rent_per_m2' },
  { label: 'potential_charges', suffix: 'units.potential_charges' },
  { label: 'potential_charges_per_m2', suffix: 'units.charges_per_m2' },
  { label: 'real_charges', suffix: 'units.real_charges' },
  // {label: 'real_roa', suffix: 'units.roa' },
  { ascFirst: true, label: 'labels' },
  { ascFirst: true, label: 'energy_source_heating' },
  { ascFirst: true, label: 'energy_source_water' },
  { label: 'estimated_idc_per_m2', suffix: 'units.idc_m2' },
  { label: 'solar_existing', suffix: 'units.solar_existing' },
  // { label: 'potential_gross_rent_per_m2' },
  // { label: 'potential_gross_rent' },

  // Newly added
  { label: 'r_market_value', suffix: 'units.r_market_value' },
  { label: 'r_real_rent', suffix: 'units.r_real_rent' },
  { label: 'r_target_rent', suffix: 'units.r_target_rent' },
  { label: 'r_rental_space', suffix: 'units.r_rental_space' },
  { label: 'r_cost_price', suffix: 'units.r_cost_price' },
  { label: 'r_gross_yield', suffix: 'units.r_gross_yield' },
];

export const commonDwellingHeaders: SuffixHeaderType[] = [
  // Default order
  // NOTE: street_short & ewid comes before - See line `dwellingHeaders`
  { ascFirst: true, label: 'locality' },
  { label: 'construction_date' },
  { label: 'floor' },
  { label: 'rooms_nb' },
  { label: 'area', suffix: 'units.area' },
  { label: 'potential_net_rent', suffix: 'units.potential_net_rent' },
  { label: 'potential_charges', suffix: 'units.potential_charges' },
  { label: 'estimated_co2_emission_per_m2', suffix: 'units.co2_m2' },
  // ENDOF Default order

  { label: 'zip' },
  { ascFirst: true, label: 'canton' },
  { ascFirst: true, label: 'region' },
  { label: 'estimated_area', suffix: 'units.area' },
  { label: 'potential_net_rent_per_m2', suffix: 'units.rent_per_m2' },
  { label: 'potential_net_rent_monthly', suffix: 'units.potential_net_rent' },
  { label: 'potential_charges_per_m2', suffix: 'units.charges_per_m2' },
  { label: 'potential_charges_monthly', suffix: 'units.potential_charges' },
  {
    label: 'potential_market_value_per_m2',
    suffix: 'units.market_value_per_m2',
  },
  { label: 'potential_roa', suffix: 'units.roa' },
  { label: 'real_net_rent', suffix: 'units.real_net_rent' },
  { label: 'real_net_rent_monthly', suffix: 'units.real_net_rent' },
  { label: 'real_charges', suffix: 'units.real_charges' },
  { label: 'real_charges_monthly', suffix: 'units.real_charges' },
  { label: 'potential_market_value', suffix: 'units.market_value' },
  { ascFirst: true, label: 'energy_source_heating' },
  { ascFirst: true, label: 'energy_source_water' },
  { ascFirst: true, label: 'labels' },
  { label: 'estimated_idc_per_m2', suffix: 'units.idc_m2' },
];

export const fundHeaders: SuffixHeaderType[] = [
  // Default order
  { ascFirst: true, label: 'short_name' },
  { label: 'isin' },
  { label: 'm_market_cap', suffix: 'units.m_market_cap' },
  { label: 'r_rental_income', suffix: 'units.r_rental_income' },
  { label: 'c_debt_ratio', suffix: 'units.c_debt_ratio' },
  { label: 'total_co2', suffix: 'units.total_co2' },
  { label: 'esg' },
  { label: 'r_total_rental_space', suffix: 'units.r_total_rental_space' },
  { label: 'building_groups_nb' },
  { label: 'buildings_nb' },
  { label: 'dwellings_nb' },
  { label: 'last_report_date' },
  // ENDOF Default order

  { ascFirst: true, label: 'name' },
  { ascFirst: true, label: 'bloomberg_ticker' },
  { ascFirst: true, label: 'type' },
  { label: 'launch_date' },
  { ascFirst: true, label: 'legal_form' },
  { label: 'r_building_expenses', suffix: 'units.r_building_expenses' },
  { label: 'r_capital_distribution', suffix: 'units.r_capital_distribution' },
  {
    label: 'r_construction_and_works_fees',
    suffix: 'units.r_construction_and_works_fees',
  },
  {
    label: 'r_estimated_liquidation_taxes',
    suffix: 'units.r_estimated_liquidation_taxes',
  },
  {
    label: 'r_maintenance_and_repairs_expenses',
    suffix: 'units.r_maintenance_and_repairs_expenses',
  },
  {
    label: 'r_profit_and_capital_taxes',
    suffix: 'units.r_profit_and_capital_taxes',
  },
  {
    label: 'r_property_management_fees',
    suffix: 'units.r_property_management_fees',
  },
  { label: 'r_property_tax', suffix: 'units.r_property_tax' },
  {
    label: 'r_purchase_and_sale_fees',
    suffix: 'units.r_purchase_and_sale_fees',
  },
  {
    label: 'r_realised_capital_gains_and_losses',
    suffix: 'units.r_realised_capital_gains_and_losses',
  },
  { label: 'r_realised_income', suffix: 'units.r_realised_income' },
  { label: 'r_retained_earnings', suffix: 'units.r_retained_earnings' },
  {
    label: 'r_revenue_from_postal_and_bank_accounts',
    suffix: 'units.r_revenue_from_postal_and_bank_accounts',
  },
  {
    label: 'r_short_term_interest_bearing_mortgages',
    suffix: 'units.r_short_term_interest_bearing_mortgages',
  },
  {
    label: 'r_short_term_liabilities',
    suffix: 'units.r_short_term_liabilities',
  },
  { label: 'r_taxes', suffix: 'units.r_taxes' },
  { label: 'r_total_cash', suffix: 'units.r_total_cash' },
  { label: 'r_total_dividend', suffix: 'units.r_total_dividend' },
  { label: 'r_total_expenses', suffix: 'units.r_total_expenses' },
  { label: 'r_total_income', suffix: 'units.r_total_income' },
  { label: 'r_total_interest', suffix: 'units.r_total_interest' },
  { label: 'r_total_liabilities', suffix: 'units.r_total_liabilities' },
  { label: 'r_total_properties', suffix: 'units.r_total_properties' },
  { label: 'r_total_revenue', suffix: 'units.r_total_revenue' },
  {
    label: 'r_unrealised_capital_gains_and_losses',
    suffix: 'units.r_unrealised_capital_gains_and_losses',
  },
  {
    label: 'r_valuation_and_auditing_expenses',
    suffix: 'units.r_valuation_and_auditing_expenses',
  },
  { label: 'r_valuation_expenses', suffix: 'units.r_valuation_expenses' },
  { label: 'environmental' },
  { label: 'social' },
  { label: 'governance' },
  { label: 'esg_rating' },
  { label: 'environmental_rating' },
  { label: 'social_rating' },
  { label: 'governance_rating' },
  { label: 'energy_intensity', suffix: 'units.energy_intensity' },
  {
    label: 'r_absolute_custodian_bank_fees',
    suffix: 'units.r_absolute_custodian_bank_fees',
  },
  {
    label: 'r_absolute_management_fees',
    suffix: 'units.r_absolute_management_fees',
  },
  {
    label: 'r_administration_of_buildings',
    suffix: 'units.r_administration_of_buildings',
  },
  { label: 'r_auditing_expenses', suffix: 'units.r_auditing_expenses' },
  { label: 'r_custodian_bank_fees', suffix: 'units.r_custodian_bank_fees' },
  { label: 'r_management_fees', suffix: 'units.r_management_fees' },
  {
    label: 'r_share_buyback_commissions',
    suffix: 'units.r_share_buyback_commissions',
  },
  {
    label: 'r_share_issue_commissions',
    suffix: 'units.r_share_issue_commissions',
  },
  { label: 'r_share_price', suffix: 'units.r_share_price' },
  { label: 'r_ter_gav', suffix: 'units.r_ter_gav' },
  { label: 'r_ter_mv', suffix: 'units.r_ter_mv' },
  { label: 'r_ter_nav', suffix: 'units.r_ter_nav' },
  { label: 'c_current_liabilities', suffix: 'units.c_current_liabilities' },
  { label: 'c_current_ratio', suffix: 'units.c_current_ratio' },
  { label: 'c_ebit_margin', suffix: 'units.c_ebit_margin' },
  { label: 'c_loan_to_value', suffix: 'units.c_loan_to_value' },
  { label: 'c_net_initial_yield', suffix: 'units.c_net_initial_yield' },
  { label: 'c_operating_profit', suffix: 'units.c_operating_profit' },
  { label: 'c_payout_ratio', suffix: 'units.c_payout_ratio' },
  { label: 'c_pe_ratio' /* , suffix: 'units.c_pe_ratio' */ }, // Is mark as % in excel but is float without unit
  { label: 'c_price_to_book_value', suffix: 'units.c_price_to_book_value' },
  { label: 'c_roa', suffix: 'units.c_roa' },
  { label: 'c_roce', suffix: 'units.c_roce' },
  { label: 'c_roe', suffix: 'units.c_roe' },
  { label: 'c_total_cash_per_share', suffix: 'units.c_total_cash_per_share' },
  { label: 'r_debt_ratio', suffix: 'units.r_debt_ratio' },
  { label: 'r_dividend_distribution', suffix: 'units.r_dividend_distribution' },
  {
    label: 'r_dividend_from_capital_gains',
    suffix: 'units.r_dividend_from_capital_gains',
  },
  {
    label: 'r_dividend_from_direct_rental_income',
    suffix: 'units.r_dividend_from_direct_rental_income',
  },
  {
    label: 'r_dividend_from_indirect_rental_income',
    suffix: 'units.r_dividend_from_indirect_rental_income',
  },
  { label: 'r_dividend_yield', suffix: 'units.r_dividend_yield' },
  { label: 'c_earnings_per_share', suffix: 'units.c_earnings_per_share' },
  { label: 'r_ebit', suffix: 'units.r_ebit' },
  { label: 'r_ebit_margin', suffix: 'units.r_ebit_margin' },
  {
    label: 'r_long_term_interest_bearing_mortgages',
    suffix: 'units.r_long_term_interest_bearing_mortgages',
  },
  { label: 'r_long_term_liabilities', suffix: 'units.r_long_term_liabilities' },
  { label: 'c_median_cost_of_debt' },
  { label: 'c_median_maturity', suffix: 'units.c_median_maturity' },
  { label: 'r_mortgage_interest', suffix: 'units.r_mortgage_interest' },
  { label: 'r_negative_interest', suffix: 'units.r_negative_interest' },
  {
    label: 'r_net_assets_at_beginning_of_the_financial_year',
    suffix: 'units.r_net_assets_at_beginning_of_the_financial_year',
  },
  {
    label: 'r_net_assets_before_estimated_liquidation_taxes',
    suffix: 'units.r_net_assets_before_estimated_liquidation_taxes',
  },
  { label: 'r_net_income', suffix: 'units.r_net_income' },
  {
    label: 'r_net_income_available_for_distribution',
    suffix: 'units.r_net_income_available_for_distribution',
  },
  { label: 'r_nopat', suffix: 'units.r_nopat' },
  {
    label: 'r_ordinary_income_carried_forward_from_previous_financial_year',
    suffix: 'units.r_ordinary_income_carried_forward_from_previous_financial_year',
  },
  { label: 'r_other_assets', suffix: 'units.r_other_assets' },
  { label: 'r_other_expenses', suffix: 'units.r_other_expenses' },
  { label: 'r_other_interest', suffix: 'units.r_other_interest' },
  {
    label: 'r_other_long_term_liabilities',
    suffix: 'units.r_other_long_term_liabilities',
  },
  { label: 'r_other_revenue', suffix: 'units.r_other_revenue' },
  {
    label: 'r_other_short_term_liabilities',
    suffix: 'units.r_other_short_term_liabilities',
  },
  { label: 'r_payout_ratio', suffix: 'units.r_payout_ratio' },
  { label: 'r_performance', suffix: 'units.r_performance' },
  { label: 'r_rental_losses', suffix: 'units.r_rental_losses' },
  { label: 'c_revenue_per_share', suffix: 'units.c_revenue_per_share' },
  { label: 'r_roa', suffix: 'units.r_roa' },
  { label: 'r_roce', suffix: 'units.r_roce' },
  { label: 'r_roe', suffix: 'units.r_roe' },
  { label: 'r_roi', suffix: 'units.r_roi' },
  { label: 'r_roic', suffix: 'units.r_roic' },
  { label: 'r_wault', suffix: 'units.r_wault' },
  {
    label: 'c_weighted_average_cost_of_debt',
    suffix: 'units.c_weighted_average_cost_of_debt',
  },
  {
    label: 'c_weighted_average_maturity',
    suffix: 'units.c_weighted_average_maturity',
  },
  { label: 'm_dividend', suffix: 'units.m_dividend' },
  { label: 'm_dividend_date' },
  { label: 'r_units_outstanding' },
  {
    label: 'c_gav_after_distribution',
    suffix: 'units.c_gav_after_distribution',
  },
  {
    label: 'c_nav_after_distribution',
    suffix: 'units.c_nav_after_distribution',
  },
  { label: 'r_agio_at_year_end', suffix: 'units.r_agio_at_year_end' },
  {
    label: 'r_gav_before_distribution',
    suffix: 'units.r_gav_before_distribution',
  },
  { label: 'r_market_cap', suffix: 'units.r_market_cap' },
  {
    label: 'r_nav_before_distribution',
    suffix: 'units.r_nav_before_distribution',
  },
  {
    label: 'r_tga_after_distribution',
    suffix: 'units.r_tga_after_distribution',
  },
  {
    label: 'r_tga_before_distribution',
    suffix: 'units.r_tga_before_distribution',
  },
  {
    label: 'r_unrealised_gains_and_losses_including_var_est_liq_tax',
    suffix: 'units.r_unrealised_gains_and_losses_including_var_est_liq_tax',
  },
  {
    label: 'r_tna_after_distribution',
    suffix: 'units.r_tna_after_distribution',
  },
  {
    label: 'r_tna_before_distribution',
    suffix: 'units.r_tna_before_distribution',
  },
  {
    label: 'c_total_agio_at_year_end',
    suffix: 'units.c_total_agio_at_year_end',
  },
  {
    label: 'c_current_agio_after_distribution',
    suffix: 'units.c_current_agio_after_distribution',
  },
  {
    label: 'c_current_agio_compounded',
    suffix: 'units.c_current_agio_compounded',
  },
  { label: 'c_gav_compounded', suffix: 'units.c_gav_compounded' },
  { label: 'c_nav_compounded', suffix: 'units.c_nav_compounded' },
  { label: 'c_tga_compounded', suffix: 'units.c_tga_compounded' },
  { label: 'c_tna_compounded', suffix: 'units.c_tna_compounded' },
  {
    label: 'c_total_current_agio_after_distribution',
    suffix: 'units.c_total_current_agio_after_distribution',
  },
  {
    label: 'c_total_current_agio_compounded',
    suffix: 'units.c_total_current_agio_compounded',
  },
  { label: 'alpha' },
  { label: 'beta' },
  { label: 'm_share_price', suffix: 'units.m_share_price' },
  { label: 'm_dividend_yield', suffix: 'units.m_dividend_yield' },
  { label: 'fifty_two_weeks_high', suffix: 'units.fifty_two_weeks_high' },
  { label: 'fifty_two_weeks_low', suffix: 'units.fifty_two_weeks_low' },
  { label: 'five_days_avg_volume' },
  {
    label: 'five_years_avg_dividend_yield',
    suffix: 'units.five_years_avg_dividend_yield',
  },
  { label: 'five_years_return', suffix: 'units.five_years_return' },
  {
    label: 'c_three_years_total_return',
    suffix: 'units.c_three_years_total_return',
  },
  {
    label: 'c_five_years_total_return',
    suffix: 'units.c_five_years_total_return',
  },
  { label: 'high', suffix: 'units.high' },
  { label: 'hist_volatility_180_days' },
  { label: 'hist_volatility_250_days' },
  { label: 'hist_volatility_30_days' },
  { label: 'hist_volatility_90_days' },
  { label: 'implied_volatility' },
  { label: 'last', suffix: 'units.last' },
  { label: 'low', suffix: 'units.low' },
  { label: 'mtd_net_return', suffix: 'units.mtd_net_return' },
  { label: 'one_month_avg_volume' },
  { label: 'one_month_return', suffix: 'units.one_month_return' },
  { label: 'one_year_avg_volume' },
  { label: 'one_year_return', suffix: 'units.one_year_return' },
  { label: 'open', suffix: 'units.open' },
  { label: 'qtd_net_return', suffix: 'units.qtd_net_return' },
  { label: 'r2' },
  { label: 'six_months_avg_volume' },
  { label: 'six_months_return', suffix: 'units.six_months_return' },
  { label: 'three_months_avg_volume' },
  { label: 'three_months_return', suffix: 'units.three_months_return' },
  { label: 'three_years_return' },
  { label: 'turnover', suffix: 'units.turnover' },
  { label: 'volume' },
  { label: 'ytd_net_return', suffix: 'units.ytd_net_return' },
  { label: 'one_year_total_return', suffix: 'units.one_year_total_return' },
  { label: 'three_years_total_return', suffix: 'units.three_years_total_return' },
  { label: 'five_years_total_return', suffix: 'units.five_years_total_return' },
  { label: 'm_share_price_var', suffix: 'units.m_share_price_var' },
  { label: 'r_commercial_rental_space', suffix: 'units.r_commercial_rental_space' },
  { label: 'r_residential_rental_space', suffix: 'units.r_residential_rental_space' },
  { label: 'r_mixed_rental_space', suffix: 'units.r_mixed_rental_space' },
  { label: 'r_plots_rental_space', suffix: 'units.r_plots_rental_space' },
  { label: 'r_other_rental_space', suffix: 'units.r_other_rental_space' },
  { label: 'lots_nb' },
  { label: 'vwap', suffix: 'units.vwap' },
  { label: 'r_capital_employed', suffix: 'units.r_capital_employed' },
  { label: 'net_rent', suffix: 'units.net_rent' },
  { label: 'potential_total_income', suffix: 'units.potential_total_income' },

  // Found in Sheets:
  // { label: 'is_annual' },
  // { label: 'canton_ids' },
];

export const transactionHeaders: SuffixHeaderType[] = [
  // Default order
  { ascFirst: true, label: 'street_short' },
  { label: 'date' },
  { ascFirst: true, label: 'locality' },
  { label: 'value', suffix: 'units.tx_price' },
  { ascFirst: true, label: 'buyer_name' },
  { ascFirst: true, label: 'seller_name' },
  // ENDOF Default order

  { label: 'zip' },
  { label: 'egid' },
  { ascFirst: true, label: 'canton' },
  { label: 'municipality_nb' },
  { ascFirst: true, label: 'region' },
  { label: 'construction_date' },
  { label: 'dwellings_nb' },
  { label: 'floors_nb' },
  { label: 'ground_area', suffix: 'units.ground_area' },
  { label: 'total_area', suffix: 'units.total_area' },
];

export const sameBuildingDwellingHeaders: SuffixHeaderType[] = [
  // { label: 'id' },
  { label: 'ewid' },
  { ascFirst: true, label: 'street_short' },
  ...commonDwellingHeaders,
];

export const dwellingHeaders: SuffixHeaderType[] = [
  // { label: 'id' },
  { ascFirst: true, label: 'street_short' },
  { label: 'ewid' },
  ...commonDwellingHeaders,
];

export const defaultColOrder: Record<ScreenerType, string[]> = {
  // regardless the pinned cols
  buildings: buildingHeaders.slice(1, 3).map(({ label, accessKey }) => accessKey || label),
  transactions: transactionHeaders.slice(2, 6).map(({ label, accessKey }) => accessKey || label),
  dwellings: dwellingHeaders.slice(2, 3).map(({ label, accessKey }) => accessKey || label),
  lots: lotHeaders.slice(1, 4).map(({ label, accessKey }) => accessKey || label),
  funds: fundHeaders.slice(1, 2).map(({ label, accessKey }) => accessKey || label),
};

export function isObjDiffer(obj1: PresetCriterias, obj2: PresetCriterias): boolean {
  const obj1Keys = Object.keys(obj1) as Array<keyof PresetCriterias>;
  const obj2Keys = Object.keys(obj2) as Array<keyof PresetCriterias>;

  const keysInObj2ButNotInObj1 = obj2Keys.filter((key) => !obj1Keys.includes(key));
  const allKeys = [...keysInObj2ButNotInObj1, ...obj1Keys];

  const isDif = allKeys.some((key) => {
    const obj1Value = obj1[key];
    const obj2Value = obj2[key];

    if (!obj2Keys.includes(key) && obj1Value !== undefined) {
      if (Array.isArray(obj1Value) && !obj1Value.length) {
        return false;
      }
      if (JSON.stringify(obj1Value) === '{}') {
        return false;
      }
      return true;
    } else if (obj1Value !== undefined && obj2Value !== undefined) {
      if (Array.isArray(obj1Value)) {
        if (!Array.isArray(obj2Value)) {
          return true;
        } else {
          const arrayDiff =
            key === 'order'
              ? !isArrEqual(obj1Value, obj2Value, true, true)
              : !isArrEqual(obj1Value, obj2Value, true);
          if (arrayDiff) return true;
          return false;
        }
      } else if (obj1Value !== obj2Value) {
        if (typeof obj1Value === 'object')
          return isObjDiffer(obj1Value as PresetCriterias, obj2Value as PresetCriterias);
        return true;
      }
    } else if (obj1Value === undefined && obj2Value !== undefined) {
      if (Array.isArray(obj2Value) && obj2Value.length === 0) {
        return false;
      }
      if (JSON.stringify(obj2Value) === '{}') {
        return false;
      }
      return true;
    }
    return false;
  });

  return isDif;
}

function convertToString(element: unknown): string {
  if (!element) return `${element}`;
  else if (Array.isArray(element)) {
    return JSON.stringify(element.map(convertToString)).toString();
  } else if (typeof element === 'object') {
    const keys = Object.keys(element) as (keyof typeof element)[];
    const converted = keys.reduce(
      (object, key) => {
        const value = element[key as keyof typeof element];
        return { ...object, [key]: convertToString(value) };
      },
      {} as Record<symbol | number | string, string>
    );
    return JSON.stringify(converted).toString();
  } else return `${element}`;
}

export function isArrEqual(
  arr1: Array<string | number | Object>,
  arr2: Array<string | number | Object>,
  regardlessType: boolean,
  respectOrder?: boolean
): boolean {
  if (arr1.length !== arr2.length) return false;
  const arr1Converted = arr1.map((element) =>
    regardlessType ? convertToString(element) : JSON.stringify(element)
  );
  const arr2Converted = arr2.map((element) =>
    regardlessType ? convertToString(element) : JSON.stringify(element)
  );
  return respectOrder
    ? arr1Converted.every((element, index) => arr2Converted[index] === element)
    : arr1Converted.every((element) => arr2Converted.includes(element));
}

export function capitalizeFirstChar(str: string | undefined) {
  if (str) return str.charAt(0).toUpperCase() + str.slice(1);
  return '';
}

export function isSelectionsEmpty(selections: PresetCriterias): boolean {
  const keys = Object.keys(selections) as Array<keyof PresetCriterias>;

  for (const key of keys) {
    if (key === 'type') continue;
    if (selections[key] !== undefined && (selections[key] as Array<string | number>).length !== 0) {
      return false;
    }
  }
  return true;
}

export function filterSelections(selections: PresetCriterias) {
  const keys = Object.keys(selections) as Array<keyof PresetCriterias>;
  const valuableKeys = keys.filter((key) => {
    if (!selections[key]) return false;
    else if (Array.isArray(selections[key]) && !(selections[key] as Array<string | number>).length)
      return false;
    else return true;
  });
  const resultSelections = valuableKeys.reduce((obj, key) => {
    return { ...obj, [key]: selections[key] };
  }, {});
  return resultSelections;
}

export function isColumnVisibilityDifferent(
  columnVisibility: VisibilityState,
  columnsToIgnore?: Array<string>
) {
  const keys = Object.keys(columnVisibility);
  const columnsIgnored = keys.reduce((acc: string[], key) => {
    if (columnVisibility[key]) return acc;
    return [...acc, key];
  }, []);
  if (columnsIgnored.length !== columnsToIgnore?.length) return true;
  const haveSameColumn = columnsIgnored.reduce((acc, column) => {
    return acc && columnsToIgnore.includes(column);
  }, true);
  return !haveSameColumn;
}

export function convertTypeToFrontEnd(type: 'lots' | 'buildings' | 'dwellings' | undefined) {
  return type === 'lots' ? 'Plots' : capitalizeFirstChar(type);
}
