import { UniqueIdentifier } from '@dnd-kit/core';
import {
  Cell,
  CellContext,
  Column,
  ColumnDef,
  Header,
  HeaderContext,
  HeaderGroup,
  Row,
  RowData,
  RowPinningPosition,
  Table,
  TableOptions,
  TableState,
} from '@tanstack/react-table';
import { Dispatch, MutableRefObject, SetStateAction } from 'react';

export type TGetSubRows<T> = (
  originalRow: TTableData<T>,
  index: number
) => TTableData<T>[] | undefined;

export interface TDataSubRows<T extends RowData> {
  subRows: TTableData<T>[];
}

export type TRef<T> = Table<TTableData<T>> | null;
export type TTableRef<T> = MutableRefObject<TRef<T>>;

type TTableDataSubRows<T extends RowData> = T & TDataSubRows<T>;

export type TTableData<T extends RowData> = T | TTableDataSubRows<T>;

export function isDataSubRow<T extends RowData>(row: unknown): row is TTableDataSubRows<T> {
  return Boolean((row as TTableDataSubRows<T>)?.subRows?.length);
}

export function isOptionsExpandable<T extends RowData>(
  options?: unknown
): options is PExpandableTable4Options<T> {
  const tmp = options as PExpandableTable4Options<T>;
  return !!tmp?.getSubRows && !!tmp?.getRowCanExpand;
}

type AllowedOptions =
  | 'enableRowSelection'
  | 'enableHiding'
  | 'enableColumnPinning'
  | 'enableRowPinning'
  | 'enableMultiSort'
  | 'manualPagination'
  | 'enableColumnResizing'
  | 'columnResizeMode'
  | 'columnResizeDirection'
  | 'enableSorting'
  | 'manualSorting'
  | 'maxMultiSortColCount'
  | 'getRowId';

// It was just for adding these comments...:
type CSpecialTableOptions<T> = {
  /**
   * WARNING: If set to false, please set enableExpanding to true or avoid using them together
   */
  enableMultiRowSelection?: TableOptions<T>['enableMultiRowSelection'];
  /**
   * WARNING: Please set it to true, if enableMultiRowSelection is set to true or avoid using them together
   */
  enableExpanding?: TableOptions<T>['enableExpanding'];
};

export type CTableOptions<T> = {
  [Key in AllowedOptions]?: TableOptions<T>[Key];
} & CSpecialTableOptions<T>;

export type CTableCustomOptions = {
  enableStickyRowPinning?: boolean;
};

export type PHeader<T> = HeaderContext<T, unknown>;
export type PCell<T> = CellContext<T, unknown>;

export type PSimpleTable4Options<T extends RowData> = CTableOptions<T> & CTableCustomOptions;
export type PExpandableTable4Options<T extends RowData> = PSimpleTable4Options<T> & {
  getRowCanExpand: Required<TableOptions<T>>['getRowCanExpand'];
  getSubRows: TGetSubRows<T>;
};
export type PTable4Options<T extends RowData> =
  | PSimpleTable4Options<T>
  | PExpandableTable4Options<T>;

export interface PTable4<T extends RowData> {
  data: TTableData<T>[];
  columns: CColumnDef<TTableData<T>>;
  /**
   * id of the div
   */
  id?: string;
  /**
   * WARNING: This will not modify state of the table.
   *
   * Even if the component passing it use it, table will be unaffected.
   */
  setState?: Dispatch<SetStateAction<TableState>>;
  /**
   * WARNING: enableMultiSort to false doesn't work properly with enableExpanding to true
   */
  options?: PTable4Options<T>;
  initialState?: Partial<TableState>;
}

export type CColumOrder = (
  | UniqueIdentifier
  | {
      id: UniqueIdentifier;
    }
)[];

export type PTableRowSize = Record<string, number>;

export type CColumnDef<T extends RowData> = ColumnDef<T>[];
export type CColumn<T extends RowData> = Column<T>;
export type CCell<T extends RowData> = Cell<T, unknown> & { parentDepth?: number };
export type CRow<T extends RowData> = Row<T> & {
  setRowsSize?: Dispatch<SetStateAction<PTableRowSize>>;
  sticky?: RowPinningPosition;
  rowsSize?: PTableRowSize;
  highlighted?: boolean;
};
export type CHeader<T extends RowData> = Header<T, unknown>;
export type CHeaderGroup<T extends RowData> = HeaderGroup<T> & {
  setRowsSize?: Dispatch<SetStateAction<PTableRowSize>>;
  columnOrder?: CColumOrder;
};

export interface PMemoizedProps {
  isExpanded?: boolean;
  isPinned?: false | RowPinningPosition;
  isSelected?: boolean;
  isSomeSelected?: boolean;
  isAllSubRowsSelected?: boolean;
  columnOrder?: CColumOrder;
  columnPinning?: TableState['columnPinning'];
  columnSizing?: TableState['columnSizing'];
  isResizing?: boolean;
}
