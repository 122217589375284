import React, { useEffect, useState } from 'react';
import { Location as ReactLocation, RouteObject, Routes, useLocation } from 'react-router-dom';

import { useAppSelector } from '../app/hooks';
import { selectSession } from '../features/kratos/kratosSlice';
import RenderRoute from './Render';

import CustomModal from '@/components/CustomModal/index';
import { selectEnterprise } from '@/features/keto/ketoSlice';
import Login from '@/pages/flows/Login';
import Impressum from '@/pages/Legals/Impressum';
import LicenseAgreement from '@/pages/Legals/LicenseAgreement';
import Policy from '@/pages/Legals/Policy';
import NotFoundPage from '@/pages/NotFoundPage';

// The foregroundLocation is the link to the modal and the location in the link of his background
// example:   <Link to={location} state={{ foregroundLocation: '/policies' }}>
// example:	navigate(location, { state: { foregroundLocation: to } });

export default function UseModalRoutes(props: { foregroundLocation?: ReactLocation }) {
  const { foregroundLocation } = props;
  const location = useLocation();
  const [routes, setRoutes] = useState<Array<RouteObject>>([]);
  const session = useAppSelector(selectSession);
  const enterprise = useAppSelector(selectEnterprise);

  useEffect(() => {
    if (session?.status === 'expired') {
      setRoutes([
        {
          path: '*',
          element: <CustomModal backgroundLocation={location.pathname} />,
          children: [
            {
              path: '*',
              element: <Login aal2={true} />,
            },
            {
              path: '*',
              element: <NotFoundPage />,
            },
          ],
        },
      ]);
    } else {
      setRoutes([
        {
          path: '/',
          element: <CustomModal backgroundLocation={location.pathname} />,
          children: [
            {
              path: '/impressum',
              element: <Impressum />,
            },
            {
              path: '/policies',
              element: <Policy />,
            },
            {
              path: '/license-agreement',
              element: <LicenseAgreement />,
            },
            {
              path: '*',
              element: <NotFoundPage />,
            },
          ],
        },
      ]);
    }
  }, [foregroundLocation, session, enterprise]);

  if (!session.boolStatus || (!foregroundLocation && session?.status !== 'expired')) return;

  return (
    <Routes location={foregroundLocation}>
      {routes.map((route, index) => (
        <React.Fragment key={route.path || index + 1}>{RenderRoute({ route })}</React.Fragment>
      ))}
    </Routes>
  );
}
