import Pia from '../pia/type';

export const fundPercentKeys: (keyof Pia.FundsOverviewReloaded)[] = [
  'r_property_management_fees',
  'r_purchase_and_sale_fees',

  'r_max_construction_and_works_fees',
  'r_max_custodian_bank_fees',
  'r_max_management_fees',
  'r_max_property_management_fees',
  'r_max_purchase_and_sale_fees',
  'r_max_share_buyback_commissions',
  'r_max_share_issue_commissions',
  'r_share_buyback_commissions',
  'r_share_issue_commissions',
  'r_management_fees',

  'r_custodian_bank_fees',
  'r_construction_and_works_fees',
  'c_ebit_margin',
  'c_median_cost_of_debt',

  'r_ter_gav',
  'r_ter_mv',
  'r_ter_nav',

  'c_current_ratio',
  'c_debt_ratio',

  'c_loan_to_value',
  'c_net_initial_yield',

  'c_payout_ratio',

  'c_price_to_book_value',
  'c_roa',
  'c_roce',
  'c_roe',
  'r_debt_ratio',

  'r_dividend_yield',

  'r_ebit_margin',

  'r_payout_ratio',
  'r_performance',
  'r_rental_losses',

  'r_roa',
  'r_roce',
  'r_roe',
  'r_roi',
  'r_roic',

  'c_weighted_average_cost_of_debt',

  'c_agio_at_year_end',

  'r_agio_at_year_end',

  'c_current_agio_after_distribution',
  'c_current_agio_compounded',

  'm_dividend_yield',

  'five_years_avg_dividend_yield',
  'five_years_return',
  'three_years_return',
  'c_three_years_total_return',
  'c_five_years_total_return',

  'mtd_net_return',
  'one_month_return',
  'one_year_return',
  'qtd_net_return',

  'six_months_return',
  'three_months_return',

  'ytd_net_return',
  'one_year_total_return',
  'three_years_total_return',
  'five_years_total_return',
  'm_share_price_var',
];

export default fundPercentKeys;
