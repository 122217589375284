import { useAppDispatch, useAppSelector } from '@/app/hooks';
import { appendNewPath } from '@/features/api/navigationSlice';
import { WebSocketProvider } from '@/features/contexts/websockets/WebSocketContext';
import UseTitle from '@/features/helpers/UseTitle';
import { selectEnterprise } from '@/features/keto/ketoSlice';
import { selectSession } from '@/features/kratos/kratosSlice';
import NotFoundPage from '@/pages/NotFoundPage';
import UseModalRoutes from '@/routes/Modals';
import { PortalProps } from '@mantine/core';
import { ModalsProvider } from '@mantine/modals';
import { Notifications } from '@mantine/notifications';
import * as Sentry from '@sentry/react';
import { useEffect, useMemo } from 'react';
import { Outlet, Location as ReactLocation, useLocation, useNavigate } from 'react-router-dom';

export default function ParentLayout() {
  const location = useLocation();
  const navigate = useNavigate();
  const session = useAppSelector(selectSession);
  const enterprise = useAppSelector(selectEnterprise);
  const dispatch = useAppDispatch();

  UseTitle();

  const state = useMemo(() => {
    return location.state as { foregroundLocation?: ReactLocation };
  }, [location]);

  useEffect(() => {
    if (
      session?.status === 'fulfilled' &&
      ['outScope', 'slotOutScope'].includes(enterprise.state) &&
      location.pathname !== '/'
    ) {
      navigate(location, { state: { foregroundLocation: '/out-of-scope' } });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [session, enterprise, navigate]);

  useEffect(() => {
    dispatch(appendNewPath(location.pathname));
  }, [location.pathname, dispatch]);

  const layoutType = useMemo(() => {
    if (location.pathname.includes('settings') || location.pathname.includes('administration'))
      return 'management-notification-holder';
    return 'main-notification-holder';
  }, [location.pathname]);

  return (
    <Sentry.ErrorBoundary fallback={<NotFoundPage isError noBackground />}>
      <WebSocketProvider>
        <ModalsProvider>
          <Notifications
            position="bottom-right"
            zIndex={501}
            autoClose={7 * 1000}
            limit={3}
            portalProps={{ target: '.app-notification-holder' } as PortalProps}
          />
          <div className={`app-notification-holder ${layoutType}`}></div>
          <Outlet />
          <UseModalRoutes foregroundLocation={state?.foregroundLocation} />
        </ModalsProvider>
      </WebSocketProvider>
    </Sentry.ErrorBoundary>
  );
}
