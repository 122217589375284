import { SerializedError } from '@reduxjs/toolkit';
import { FetchBaseQueryError } from '@reduxjs/toolkit/dist/query';
import { useEffect, useState } from 'react';

const DEFAULTMSG = 'Something wrong happened';

export interface UIFiiError {
  code: string | number;
  userMessage: string;
  details: Array<string>;
  uuid: string;
}

export interface RTKError {
  originalStatus: number;
  error: string;
  data: string;
  status: string;
}

export type RTKResult<T extends unknown> =
  | {
      data: T;
      error: undefined;
    }
  | {
      data: undefined;
      error: FetchBaseQueryError | SerializedError;
    };

export function isFiiError(error: unknown): error is UIFiiError {
  if (!error) return false;
  const tmp = error as UIFiiError;
  const { code, userMessage, uuid } = tmp;
  return (
    (typeof code === 'string' || typeof code === 'number') &&
    typeof uuid === 'string' &&
    typeof userMessage === 'string'
  );
}

export function isRTKError(error: unknown): error is RTKError {
  if (!error) return false;
  const tmp = error as RTKError;
  const { error: detail, data, status, originalStatus } = tmp;
  return (
    typeof detail === 'string' &&
    typeof originalStatus === 'number' &&
    typeof data === 'string' &&
    typeof status === 'string'
  );
}

export function isStrictFetchBaseQueryError(error: unknown): error is FetchBaseQueryError {
  if (!error) return false;
  const tmp = error as FetchBaseQueryError;
  const { data, status } = tmp;
  const message = (tmp as { error: string }).error;

  return !!status && (!!data || typeof message === 'string');
}

export function isSerializedError(error: unknown): error is SerializedError {
  if (!error) return false;
  const tmp = error as SerializedError;
  const { code, message, name, stack } = tmp;

  return (
    (!code || typeof code === 'string') &&
    (!message || typeof message === 'string') &&
    (!name || typeof name === 'string') &&
    (!stack || typeof stack === 'string') &&
    Boolean(code || message || name || stack)
  );
}

export function parseRTKError(error: unknown): Partial<UIFiiError> | undefined {
  if (isSerializedError(error)) {
    const { code, message = DEFAULTMSG } = error;
    return { code, userMessage: message };
  }
  if (isRTKError(error)) {
    const { data: message, error: detail, /* originalStatus: status, */ status: code } = error;
    return { code, userMessage: message || detail };
  }
  if (isStrictFetchBaseQueryError(error)) {
    const { status, data } = error;
    if (isFiiError(data)) {
      const { details } = data;
      if (details) {
        const formated = details
          .map((str) => {
            const [path, detail] = str.split(':').filter(Boolean);
            const trimmed = path.trim();
            if (trimmed) {
              const splited = trimmed.split('.').filter(Boolean);
              if (!splited.some((str) => str.includes(' '))) {
                return detail;
              }
            }
          })
          .filter(Boolean);
        if (formated.length)
          return {
            ...data,
            userMessage: [data.userMessage, ...formated].filter(Boolean).join('\n'),
          };
      }
      return data;
    }
    const { error: message } = error as { error?: string };
    if (message) return { code: status, userMessage: message };
    // handle kratos verification flow error
    const errorInData = (data as { error?: { message: string } }).error;
    const errorMsg = errorInData?.message;
    if (errorMsg) return { code: status, userMessage: errorMsg };
    // TODO: Deal with Kratos return UiNode
  }
}

export function UseParseError(error: unknown) {
  const [message, setMessage] = useState<string | Array<string> | undefined>(undefined);
  const [code, setCode] = useState<string | number | undefined>(undefined);
  const [details, setDetails] = useState<Array<string> | undefined>(undefined);

  useEffect(() => {
    if (typeof error === 'string') setMessage(error);
    else {
      const fiiError = parseRTKError(error);
      if (fiiError?.userMessage) setMessage(fiiError.userMessage);
      else setMessage(undefined);
      if (fiiError?.code) setCode(fiiError.code);
      else setCode(undefined);
      if (fiiError?.details) setDetails(fiiError.details);
      else setDetails(undefined);
    }
  }, [error]);

  return { message, code, details };
}
