import '@/scss/components/Policy.css';
import { ScrollArea } from '@mantine/core';
import { useViewportSize } from '@mantine/hooks';
import { Trans, useTranslation } from 'react-i18next';

// update the date if we update the content in the agreement YYYY-MM-DD
export const LAST_UPDATE_DATE = new Date('2024-12-11');

export default function LicenseAgreement() {
  const { height } = useViewportSize();
  const { t } = useTranslation();

  return (
    <ScrollArea.Autosize
      className="policy-container-scrollarea"
      mah={height - 210}
      scrollbarSize={8}
      scrollHideDelay={1000}
      type="hover"
    >
      <div className="policy-container">
        <h1>{t('license_agreement.title')}</h1>
        <p>
          <Trans
            i18nKey="license_agreement.intro.first"
            components={{
              strong: <strong style={{ fontWeight: 600 }} />,
            }}
          />
        </p>
        <p>{t('license_agreement.intro.second')}</p>

        <h1>{t('license_agreement.part1.title')}</h1>
        <ul className="list-with-style">
          <li>
            <p>a.</p>
            <p>
              <Trans
                i18nKey="license_agreement.part1.content1"
                components={{
                  strong: <strong style={{ fontWeight: 600 }} />,
                }}
              />
            </p>
          </li>
          <li>
            <p>b.</p>
            <p>
              <Trans
                i18nKey="license_agreement.part1.content2"
                components={{
                  strong: <strong style={{ fontWeight: 600 }} />,
                }}
              />
            </p>
          </li>
          <li>
            <p>c.</p>
            <p>
              <Trans
                i18nKey="license_agreement.part1.content3"
                components={{
                  strong: <strong style={{ fontWeight: 600 }} />,
                }}
              />
            </p>
          </li>
        </ul>

        <h1>{t('license_agreement.part2.title')}</h1>
        <p>
          <Trans
            i18nKey="license_agreement.part2.content"
            components={{
              strong: <strong style={{ fontWeight: 600 }} />,
            }}
          />
        </p>

        <h1>{t('license_agreement.part3.title')}</h1>
        <ul className="list-with-style">
          <li>
            <p>a.</p>
            <p>{t('license_agreement.part3.content1')}</p>
          </li>
          <li>
            <p>b.</p>
            <p>{t('license_agreement.part3.content2')}</p>
          </li>
          <li>
            <p>c.</p>
            <p>{t('license_agreement.part3.content3')}</p>
          </li>
          <li>
            <p>d.</p>
            <p>{t('license_agreement.part3.content4')}</p>
          </li>
          <li>
            <p>e.</p>
            <p>{t('license_agreement.part3.content5')}</p>
          </li>
          <li>
            <p>f.</p>
            <p>{t('license_agreement.part3.content6')}</p>
          </li>
        </ul>

        <h1>{t('license_agreement.part4.title')}</h1>
        <p>{t('license_agreement.part4.content1')}</p>
        <p>{t('license_agreement.part4.content2')}</p>

        <h1>{t('license_agreement.part5.title')}</h1>
        <p>{t('license_agreement.part5.content1')}</p>
        <p>{t('license_agreement.part5.content2')}</p>

        <h1>{t('license_agreement.part6.title')}</h1>
        <p>{t('license_agreement.part6.content1')}</p>
        <p>{t('license_agreement.part6.content2')}</p>

        <h1>{t('license_agreement.part7.title')}</h1>
        <p>{t('license_agreement.part7.content1')}</p>
        <p>{t('license_agreement.part7.content2')}</p>
        <ul className="list-with-style">
          <li>
            <p>a.</p>
            <p>{t('license_agreement.part7.content3')}</p>
          </li>
          <li>
            <p>b.</p>
            <p>{t('license_agreement.part7.content4')}</p>
          </li>
          <li>
            <p>c.</p>
            <p>{t('license_agreement.part7.content5')}</p>
          </li>
          <li>
            <p>d.</p>
            <p>{t('license_agreement.part7.content6')}</p>
          </li>
          <li>
            <p>e.</p>
            <p>{t('license_agreement.part7.content7')}</p>
          </li>
        </ul>
        <p>{t('license_agreement.part7.content8')}</p>
        <p>{t('license_agreement.part7.content9')}</p>
        <p>{t('license_agreement.part7.content10')}</p>

        <h1>{t('license_agreement.part8.title')}</h1>
        <ul className="list-with-style">
          <li>
            <p>a.</p>
            <p>{t('license_agreement.part8.content1')}</p>
          </li>
          <li>
            <p>b.</p>
            <p>{t('license_agreement.part8.content2')}</p>
          </li>
          <li>
            <p>c.</p>
            <p>{t('license_agreement.part8.content3')}</p>
          </li>
          <li>
            <p>d.</p>
            <p>{t('license_agreement.part8.content4')}</p>
          </li>
          <li>
            <p>e.</p>
            <p>{t('license_agreement.part8.content5')}</p>
          </li>
          <li>
            <p>f.</p>
            <p>{t('license_agreement.part8.content6')}</p>
          </li>
          <li>
            <p>g.</p>
            <p>{t('license_agreement.part8.content7')}</p>
          </li>
        </ul>

        <h1>{t('license_agreement.part9.title')}</h1>
        <ul className="list-with-style">
          <li>
            <p>a.</p>
            <p>{t('license_agreement.part9.content1')}</p>
          </li>
          <li>
            <p>b.</p>
            <p>{t('license_agreement.part9.content2')}</p>
          </li>
          <li>
            <p>c.</p>
            <p>{t('license_agreement.part9.content3')}</p>
          </li>
          <li>
            <p>d.</p>
            <p>{t('license_agreement.part9.content4')}</p>
          </li>
        </ul>

        <h1>{t('license_agreement.part10.title')}</h1>
        <p>{t('license_agreement.part10.content1')}</p>
        <p>{t('license_agreement.part10.content2')}</p>

        <h1>{t('license_agreement.part11.title')}</h1>
        <p>{t('license_agreement.part11.content1')}</p>
        <ul className="list-with-style">
          <li>
            <p>-</p>
            <p>{t('license_agreement.part11.content2')}</p>
          </li>
          <li>
            <p>-</p>
            <p>{t('license_agreement.part11.content3')}</p>
          </li>
          <li>
            <p>-</p>
            <p>{t('license_agreement.part11.content4')}</p>
          </li>
          <li>
            <p>-</p>
            <p>{t('license_agreement.part11.content5')}</p>
          </li>
          <li>
            <p>-</p>
            <p>{t('license_agreement.part11.content6')}</p>
          </li>
          <li>
            <p>-</p>
            <p>{t('license_agreement.part11.content7')}</p>
          </li>
          <li>
            <p>-</p>
            <p>{t('license_agreement.part11.content8')}</p>
          </li>
        </ul>
        <p>
          <Trans
            i18nKey="license_agreement.part11.content1"
            components={{
              strong: <strong style={{ fontWeight: 600 }} />,
            }}
          />
        </p>
        <p>{t('license_agreement.part11.content9')}</p>
        <p>{t('license_agreement.part11.content10')}</p>

        <h1>{t('license_agreement.part12.title')}</h1>
        <ul className="list-with-style">
          <li>
            <p>a.</p>
            <p>{t('license_agreement.part12.content1')}</p>
          </li>
          <li>
            <p>b.</p>
            <p>{t('license_agreement.part12.content2')}</p>
          </li>
          <li>
            <p>c.</p>
            <p>{t('license_agreement.part12.content3')}</p>
          </li>
          <li>
            <p>d.</p>
            <p>{t('license_agreement.part12.content4')}</p>
          </li>
          <li>
            <p>e.</p>
            <p>{t('license_agreement.part12.content5')}</p>
          </li>
          <li>
            <p>f.</p>
            <p>{t('license_agreement.part12.content6')}</p>
          </li>
          <li>
            <p>g.</p>
            <p>{t('license_agreement.part12.content7')}</p>
          </li>
        </ul>

        <h1>{t('license_agreement.part13.title')}</h1>
        <ul className="list-with-style">
          <li>
            <p>a.</p>
            <p>{t('license_agreement.part13.content1')}</p>
          </li>
          <li>
            <p>b.</p>
            <p>{t('license_agreement.part13.content2')}</p>
          </li>
        </ul>
      </div>
    </ScrollArea.Autosize>
  );
}
