import { createSlice } from '@reduxjs/toolkit';
import kratosApi from '.';
import { RootState } from '../../app/store';
import { appMutex } from '../helpers/reAuth';
import { AppSession, SessionState } from './types';

const initialState: SessionState = {
  session: { status: 'initialising', boolStatus: false },
};

function getStatus(session: Partial<AppSession>): AppSession['status'] {
  const identity = session.identity?.traits;
  const userIsSet = Boolean(identity?.name.first && identity?.name.last);
  const methods = session.authentication_methods;
  if (userIsSet) {
    if (
      methods?.[0]?.method === 'code_recovery' &&
      !methods.find(({ method }) => method === 'password')
    )
      return 'recovery';
    if ((session.authenticator_assurance_level as string) === 'aal1') return 'require-totp-setup';
    if (session.status === 'require-totp') return 'require-totp';
    return 'fulfilled';
  } else {
    if (session.identity === null && (session.authenticator_assurance_level as string) === 'aal1') {
      return 'require-totp';
    }
    return 'onboarding';
  }
}

export const kratosSlice = createSlice({
  name: 'kratos',
  initialState,
  reducers: {
    expireSession(state) {
      if (!appMutex.isLocked()) {
        appMutex.acquire();
        state.session.status = 'expired';
      }
    },
    cancelExpiration(state) {
      if (appMutex.isLocked()) {
        appMutex.release();
        state.session.status = 'fulfilled';
      }
    },
    deleteSession(state) {
      if (!appMutex.isLocked()) {
        appMutex.acquire();
        state.session.status = 'failed';
      }
    },
  },
  extraReducers: (builder) => {
    builder
      .addMatcher(kratosApi.endpoints.postLogin.matchFulfilled, (state, action) => {
        const session = action?.payload?.session;
        if (!session) state.session = { ...action.payload, status: 'failed', boolStatus: false };
        else {
          const status = getStatus(session);
          state.session = { ...session, status, boolStatus: true };
        }
        if (appMutex.isLocked()) {
          appMutex.release();
        }
      })
      .addMatcher(kratosApi.endpoints.getSession.matchFulfilled, (state, action) => {
        const session = action?.payload?.session;
        if (!session) state.session = { ...action.payload, status: 'failed', boolStatus: false };
        else {
          const status = getStatus(session);
          state.session = { ...session, status, boolStatus: true };
        }
        if (appMutex.isLocked()) {
          appMutex.release();
        }
      })
      .addMatcher(kratosApi.endpoints.getSession.matchRejected, (state, action) => {
        const error = (action.payload?.data as { error?: { id: string | undefined } })?.error?.id;
        if (error === 'session_aal2_required') {
          state.session = { ...state.session, status: 'require-totp', boolStatus: true };
          return;
        }
        if (action.error.name === 'ConditionError')
          // I don't remember what is this if statement...
          return;
        state.session = { ...action.payload, status: 'failed', boolStatus: false };
      })
      .addMatcher(kratosApi.endpoints.getSession.matchPending, (state) => {
        state.session.status = 'pending';
      })
      .addMatcher(kratosApi.endpoints.logout.matchFulfilled, (state) => {
        state.session = { status: 'failed', boolStatus: false };
      })
      .addMatcher(kratosApi.endpoints.logout.matchPending, (state) => {
        state.session = { status: 'logout', boolStatus: false };
      });
  },
});

export const selectSession = (state: RootState): AppSession => state.kratos.session;
const kratosReducer = kratosSlice.reducer;
export default kratosReducer;
export const { expireSession, cancelExpiration, deleteSession } = kratosSlice.actions;
