import { TableSort } from '@/components/Funds/Detail/AssetList/type';
import { formatBuildingsRow, formatDwellingsRow } from '@/features/api/formatRowHelper';
import {
  formatDemographicAges,
  formatDemographicForeigners,
  formatDemographicGender,
} from '@/pages/sections/helper';
import Funds from '../funds/types';
import api from '../piaApi';
import Pia from '../type';
import { formatOverview, formatTimeSeries } from './helpers';
import Portfolios from './types';

const portfolioApi = api
  .enhanceEndpoints({ addTagTypes: ['Portfolios', 'PortfoliosContains', 'PortfoliosList'] })
  .injectEndpoints({
    endpoints: (builder) => ({
      listPortfolios: builder.query({
        query: (
          { page = 1, search: title }: { page?: number; search?: string } | void | undefined = {
            page: 1,
            search: '',
          }
        ) => ({ url: `portfolios`, params: title ? { page, title } : { page } }),
        transformResponse: (data: { portfolios: Array<{ id: string; name: string }> }) => {
          return data.portfolios;
        },
        providesTags: () => {
          return ['PortfoliosList'];
        },
      }),
      getPortfolioOverview: builder.query({
        query: (id: string) => ({ url: `portfolios/${id}/overview` }),
        transformResponse: formatOverview,
        providesTags: (result) => {
          return result?.id
            ? [{ type: 'Portfolios', id: result.id, section: 'Overview' }, 'Portfolios']
            : ['Portfolios'];
        },
        extraOptions: { oneAtATime: false },
      }),
      searchPortfolios: builder.query<
        Array<{ id: number; type: Pia.SearchTypes; label: string }>,
        string
      >({
        query: (text: string) => `search?type=portfolio&text=${text}`,
        transformResponse: (data: {
          results: Array<{ id: number; type: Pia.SearchTypes; label: string }>;
        }) => {
          return data.results;
        },
      }),
      deletePortfolio: builder.mutation<void, string | undefined>({
        query: (id) => ({ url: `portfolios/${id}`, method: 'DELETE' }),
        invalidatesTags: ['PortfoliosList'],
      }),
      removePortfolioAsset: builder.mutation<
        void,
        { id: string; buildings?: Array<number | string>; dwellings?: Array<number | string> }
      >({
        query: ({ id, buildings = [], dwellings = [] }) => ({
          url: `portfolios/${id}`,
          method: 'DELETE',
          body: { buildings, dwellings },
        }),
        invalidatesTags: (_result, error) =>
          error ? [] : [{ type: 'Portfolios', section: 'Assets' }],
      }),
      addPortfolioAsset: builder.mutation<
        void,
        {
          id: string;
          buildings?: Array<number | string>;
          dwellings?: Array<number | string>;
          buildingGroups?: Array<number | string>;
          funds?: Array<number | string>;
        }
      >({
        query: ({
          id,
          buildings = [],
          dwellings = [],
          buildingGroups = [],
          funds = [],
          type,
        }: {
          id: string;
          buildings?: Array<string | number>;
          dwellings?: Array<string | number>;
          funds?: Array<string | number>;
          buildingGroups?: Array<string | number>;
          type: 'asset' | 'transaction';
        }) => ({
          url: `portfolios/${id}`,
          method: 'PATCH',
          body: { buildings, dwellings, buildingGroups, funds, type },
        }),
        invalidatesTags: (_result, error) =>
          error ? [] : [{ type: 'Portfolios', section: 'Assets' }],
      }),
      getPortfolio: builder.query({
        query: (portfolioId: string) => `portfolios/${portfolioId}`,
        transformResponse: (data: { portfolio: Pia.Portfolio }) => {
          return data.portfolio;
        },
        providesTags: (result, _error, query) => {
          if (!result) return [];
          return [{ type: 'Portfolios', id: query }];
        },
      }),
      getContainsDwellings: builder.mutation({
        query: ({
          portfolioId,
          buildingId = [],
          buildingGroup = [],
        }: {
          portfolioId: string;
          buildingId: number | Array<number>;
          buildingGroup?: number | Array<number>;
        }) => {
          const buildingIds = Array.isArray(buildingId) ? buildingId : [buildingId];
          const buildingGroupIds = Array.isArray(buildingGroup) ? buildingGroup : [buildingGroup];
          return {
            url: `portfolios/${portfolioId}/contains-dwellings`,
            params: {
              buildingId: buildingIds.length ? buildingIds : undefined,
              buildingGroup: buildingGroupIds.length ? buildingGroupIds : undefined,
            },
          };
        },
        transformResponse: (data: { contains_dwellings: Boolean }) => {
          return data.contains_dwellings;
        },
      }),
      getDwellingOfPortfolio: builder.query({
        query: ({
          id,
          page = 1,
          sort,
        }: {
          id: string | number;
          page?: number;
          sort: TableSort[] | undefined;
        }) => ({
          url: `portfolios/${id}/assets/dwellings`,
          params: sort ? { page, sort: JSON.stringify(sort) } : { page },
        }),
        providesTags: (result) =>
          result ? [{ type: 'Portfolios', section: 'Assets' }, 'Portfolios'] : [],
        transformResponse: formatDwellingsRow,
      }),
      getBuildingOfPortfolio: builder.query({
        query: ({
          id,
          page = 1,
          sort,
        }: {
          id: string | number;
          page?: number;
          sort: TableSort[] | undefined;
        }) => ({
          url: `portfolios/${id}/assets/buildings`,
          params: sort ? { page, sort: JSON.stringify(sort) } : { page },
        }),
        providesTags: (result) =>
          result ? [{ type: 'Portfolios', section: 'Assets' }, 'Portfolios'] : [],
        transformResponse: formatBuildingsRow,
      }),
      createPortfolio: builder.mutation({
        query: (data: {
          title: string;
          type?: 'asset' | 'transaction';
          buildings?: Array<number>;
          dwellings?: Array<number>;
          buildingGroups?: Array<number>;
          funds?: Array<number | string>;
        }) => ({
          url: 'portfolios',
          method: 'POST',
          body: {
            title: data.title,
            type: data.type,
            buildings: data.buildings || [],
            dwellings: data.dwellings || [],
            buildingGroups: data.buildingGroups || [],
            funds: data.funds || [],
          },
        }),
        invalidatesTags: ['PortfoliosList'],
        transformResponse: (data: { portfolio: Pia.Portfolio }) => {
          return data.portfolio;
        },
      }),
      updatePortfolio: builder.mutation({
        query: (data: {
          id: string | number;
          title: string;
          buildings?: Array<number | string>;
          dwellings?: Array<number | string>;
          buildingGroups?: Array<number | string>;
        }) => ({
          url: `portfolios/${data.id}`,
          method: 'PATCH',
          body: {
            title: data.title,
            buildings: data.buildings,
            dwellings: data.dwellings,
            buildingGroups: data.buildingGroups,
          },
        }),
        invalidatesTags: ['Portfolios'],
      }),
      getPortfolioDesignation: builder.query<Portfolios.Designation, { id: string }>({
        query: ({ id }) => ({
          url: `portfolios/${id}/description/designation`,
        }),
        providesTags: (result, _error, query) => {
          if (!result) return [];
          const { id } = query;
          return [
            { type: 'Portfolios', id, section: 'Designation' },
            { type: 'Portfolios', id },
          ];
        },
      }),
      getPortfolioComposition: builder.query<Portfolios.Composition, { id: string }>({
        query: ({ id }) => ({
          url: `portfolios/${id}/description/composition`,
        }),
        providesTags: (result, _error, query) => {
          if (!result) return [];
          const { id } = query;
          return [
            { type: 'Portfolios', id, section: 'Composition' },
            { type: 'Portfolios', id },
          ];
        },
      }),
      getPortfolioDistribution: builder.query<
        Portfolios.Distribution,
        { id: string; groupBy: string; weightBy: string }
      >({
        query: ({ id, groupBy, weightBy }) => ({
          url: `portfolios/${id}/description/distribution`,
          params: { groupBy, weightBy },
        }),
        providesTags: (result, _error, query) => {
          if (!result) return [];
          const { id, groupBy, weightBy } = query;
          return [
            { type: 'Portfolios', id, section: `Distribution-${groupBy}-${weightBy}` },
            { type: 'Portfolios', id },
          ];
        },
      }),
      getPortfolioRevenue: builder.query<Funds.Financials, { id: string }>({
        query: ({ id }) => ({
          url: `portfolios/${id}/financials/rent`,
        }),
        providesTags: (result, _error, query) => {
          if (!result) return [];
          const { id } = query;
          return [
            { type: 'Portfolios', id, section: 'Revenue' },
            { type: 'Portfolios', id },
          ];
        },
      }),
      getPortfolioValueReported: builder.query<Portfolios.ReportedMarketValue, { id: string }>({
        query: ({ id }) => ({
          url: `portfolios/${id}/financials/portfolio-value/reported`,
        }),
        transformResponse: ({
          reported_market_value,
        }: {
          reported_market_value: Portfolios.ReportedMarketValue;
        }) => {
          return reported_market_value;
        },
        providesTags: (result, _error, query) => {
          if (!result) return [];
          const { id } = query;
          return [
            { type: 'Portfolios', id, section: 'PortfolioValueReported' },
            { type: 'Portfolios', id },
          ];
        },
      }),
      getPortfolioValuePotential: builder.query<Portfolios.PotentialMarketValue, { id: string }>({
        query: ({ id }) => ({
          url: `portfolios/${id}/financials/portfolio-value/potential`,
        }),
        transformResponse: ({
          potential_market_value,
        }: {
          potential_market_value: Portfolios.PotentialMarketValue;
        }) => {
          return potential_market_value;
        },
        providesTags: (result, _error, query) => {
          if (!result) return [];
          const { id } = query;
          return [
            { type: 'Portfolios', id, section: 'PortfolioValuePotential' },
            { type: 'Portfolios', id },
          ];
        },
      }),
      getPortfolioReportedRentalIncome: builder.query<
        Portfolios.ReportedRentalIncome,
        { id: string }
      >({
        query: ({ id }) => ({
          url: `portfolios/${id}/financials/rental-income/reported`,
        }),
        transformResponse: ({
          reported_rental_income,
        }: {
          reported_rental_income: Portfolios.ReportedRentalIncome;
        }) => {
          return reported_rental_income;
        },
        providesTags: (result, _error, query) => {
          if (!result) return [];
          const { id } = query;
          return [
            { type: 'Portfolios', id, section: 'RentalIncomeReported' },
            { type: 'Portfolios', id },
          ];
        },
      }),
      getPortfolioEstimatedRentalIncome: builder.query<
        Portfolios.PotentialRentalIncome,
        { id: string }
      >({
        query: ({ id }) => ({
          url: `portfolios/${id}/financials/rental-income/estimated`,
        }),
        transformResponse: ({
          estimated_rental_income,
        }: {
          estimated_rental_income: Portfolios.PotentialRentalIncome;
        }) => {
          return estimated_rental_income;
        },
        providesTags: (result, _error, query) => {
          if (!result) return [];
          const { id } = query;
          return [
            { type: 'Portfolios', id, section: 'RentalIncomeEstimated' },
            { type: 'Portfolios', id },
          ];
        },
      }),
      getPortfolioRevenueSeries: builder.query<Funds.FinancialsSerie, { id: string }>({
        query: ({ id }) => ({
          url: `portfolios/${id}/financials/rent-series`,
        }),
        providesTags: (result, _error, query) => {
          if (!result) return [];
          const { id } = query;
          return [
            { type: 'Portfolios', id, section: 'Revenue' },
            { type: 'Portfolios', id },
          ];
        },
      }),
      getPortfolioCharges: builder.query<Funds.Financials, { id: string }>({
        query: ({ id }) => ({
          url: `portfolios/${id}/financials/charges`,
        }),
      }),
      getFinancialTimeSeries: builder.query<
        Portfolios.FormatedTimeSeries | undefined,
        { id: string; deltaT?: Extract<Funds.DeltaTime, '6M' | '1Y' | '2Y' | '3Y' | '4Y' | '5Y'> }
      >({
        query: ({ id, deltaT }) => ({
          url: `portfolios/${id}/financials/time-series`,
          params: { deltaT },
        }),
        transformResponse: (res: { time_series: Portfolios.TimeSeriesData[] }) => {
          return formatTimeSeries(res.time_series);
        },
        providesTags: (result, _error, query) => {
          if (!result) return [];
          const { id, deltaT } = query;
          return [
            { type: 'Portfolios', id, section: `Financial-TimeSeries-${deltaT}` },
            { type: 'Portfolios', id },
          ];
        },
      }),
      getEnvironmentalTimeSeries: builder.query<
        Portfolios.FormatedTimeSeries | undefined,
        { id: string; deltaT?: Extract<Funds.DeltaTime, '6M' | '1Y' | '2Y' | '3Y' | '4Y' | '5Y'> }
      >({
        query: ({ id, deltaT }) => ({
          url: `portfolios/${id}/environmental/time-series`,
          params: { deltaT },
        }),
        transformResponse: (res: { time_series: Portfolios.TimeSeriesData[] }) => {
          return formatTimeSeries(res.time_series, undefined, true);
        },
        providesTags: (result, _error, query) => {
          if (!result) return [];
          const { id, deltaT } = query;
          return [
            { type: 'Portfolios', id, section: `Environmental-TimeSeries-${deltaT}` },
            { type: 'Portfolios', id },
          ];
        },
      }),
      getPortfolioRisks: builder.query<Portfolios.Risks, { id: string }>({
        query: ({ id }) => ({
          url: `portfolios/${id}/environmental/risk`,
        }),
        providesTags: (result, _error, query) => {
          if (!result) return [];
          const { id } = query;
          return [
            { type: 'Portfolios', id, section: 'Risks' },
            { type: 'Portfolios', id },
          ];
        },
      }),

      getPortfolioHeating: builder.query<Pia.Heating, { id: string }>({
        query: ({ id }) => ({
          url: `portfolios/${id}/environmental/heating`,
        }),
        providesTags: (result, _error, query) => {
          if (!result) return [];
          const { id } = query;
          return [
            { type: 'Portfolios', id, section: 'Heating' },
            { type: 'Portfolios', id },
          ];
        },
      }),
      getPortfolioSolar: builder.query<Pia.Solar, { id: string }>({
        query: ({ id }) => ({
          url: `portfolios/${id}/environmental/solar`,
        }),
        providesTags: (result, _error, query) => {
          if (!result) return [];
          const { id } = query;
          return [
            { type: 'Portfolios', id, section: 'Solar' },
            { type: 'Portfolios', id },
          ];
        },
      }),
      getPortfolioIdcCo2: builder.query<Pia.IdcCo2, { id: string; deltaT?: string }>({
        query: ({ id, deltaT }) => ({
          url: deltaT
            ? `portfolios/${id}/environmental/idc-co2?deltaT=${deltaT}`
            : `portfolios/${id}/environmental/idc-co2`,
        }),
        transformResponse: ({ idc_co2 }: { idc_co2: Pia.IdcCo2 }) => {
          return idc_co2;
        },
        providesTags: (result, _error, query) => {
          if (!result) return [];
          const { id } = query;
          return [
            { type: 'Portfolios', id, section: 'idc_co2' },
            { type: 'Portfolios', id },
          ];
        },
      }),
      getPortfolioPromiscuity: builder.query<Pia.Promiscuity, { id: string }>({
        query: ({ id }) => ({
          url: `portfolios/${id}/social/promiscuity`,
        }),
        providesTags: (result, _error, query) => {
          if (!result) return [];
          const { id } = query;
          return [
            { type: 'Portfolios', id, section: 'Promiscuity' },
            { type: 'Portfolios', id },
          ];
        },
      }),
      getPortfolioDemographicAges: builder.query<
        ReturnType<typeof formatDemographicAges>,
        { id: string }
      >({
        query: ({ id }) => ({
          url: `portfolios/${id}/social/demographic/ages-by-gender`,
        }),
        transformResponse: (ages: Pia.Ages) => {
          return formatDemographicAges(ages);
        },
        providesTags: (result, _error, query) => {
          if (!result) return [];
          const { id } = query;
          return [
            { type: 'Portfolios', id, section: 'Ages' },
            { type: 'Portfolios', id },
          ];
        },
      }),
      getPortfolioDemographicGender: builder.query<
        ReturnType<typeof formatDemographicGender>,
        { id: string }
      >({
        query: ({ id }) => ({
          url: `portfolios/${id}/social/demographic/gender`,
        }),
        transformResponse: (gender: Pia.Gender) => {
          return formatDemographicGender(gender);
        },
        providesTags: (result, _error, query) => {
          if (!result) return [];
          const { id } = query;
          return [
            { type: 'Portfolios', id, section: 'Gender' },
            { type: 'Portfolios', id },
          ];
        },
      }),
      getPortfolioDemographicForeigners: builder.query<
        ReturnType<typeof formatDemographicForeigners>,
        { id: string }
      >({
        query: ({ id }) => ({
          url: `portfolios/${id}/social/demographic/citizenship`,
        }),
        transformResponse: (citizenship: Pia.Foreigners) => {
          return formatDemographicForeigners(citizenship);
        },
        providesTags: (result, _error, query) => {
          if (!result) return [];
          const { id } = query;
          return [
            { type: 'Portfolios', id, section: 'Foreigners' },
            { type: 'Portfolios', id },
          ];
        },
      }),

      getPortfolioDistanceAccessibility: builder.query<
        Funds.Distance & { score: Funds.Risks['risk_index'] },
        { id: string | number }
      >({
        query: ({ id }) => ({
          url: `portfolios/${id}/social/accessibility`,
        }),
        providesTags: (result, _error, query) => {
          if (!result) return [];
          const { id } = query;
          return [
            { type: 'Portfolios', id, section: 'Accessibility' },
            { type: 'Portfolios', id },
          ];
        },
      }),
      getPortfolioDensity: builder.query<{ density: Pia.Density }, { id: string | number }>({
        query: ({ id }) => ({
          url: `portfolios/${id}/social/density`,
        }),
        providesTags: (result, _error, query) => {
          if (!result) return [];
          const { id } = query;
          return [
            { type: 'Portfolios', id, section: 'Density' },
            { type: 'Portfolios', id },
          ];
        },
      }),
      getPortfolioTransportAccessibility: builder.query<
        { transport_accessibility: Pia.TransportAccessibility },
        { id: string }
      >({
        query: ({ id }) => ({
          url: `portfolios/${id}/social/transport-accessibility`,
        }),
        providesTags: (result, _error, query) => {
          if (!result) return [];
          const { id } = query;
          return [
            { type: 'Portfolios', id, section: 'TransportAccessibility' },
            { type: 'Portfolios', id },
          ];
        },
      }),
    }),
  });

export default portfolioApi;
