import { Formater } from '@/components/helpers/Financials/helpers';
import { ObjectListItemType } from '@/components/ObjectList/type';
import { formatFullInt, formatPercentToString, verifyNumber } from '@/features/helpers/numbers';
import Portfolios from './types';

const portfolioOverviewDescriptionTuple: Array<
  [keyof Portfolios.PortfolioOverview, string, string, Formater]
> = [
  [
    'potential_market_value',
    'portfolios.overview.potential_market_value',
    'units.market_value',
    formatFullInt as Formater,
  ],
  [
    'potential_net_rent',
    'portfolios.overview.potential_net_rent',
    'units.net_rent',
    formatFullInt as Formater,
  ],
  [
    'potential_charges',
    'portfolios.overview.potential_charges',
    'units.charges',
    formatFullInt as Formater,
  ],
  [
    'potential_roa',
    'portfolios.overview.potential_roa',
    'units.roa',
    formatPercentToString as Formater,
  ],
];

const portfolioOverviewLegendTuple: Array<[keyof Portfolios.PortfolioOverview, string]> = [
  ['total_area', 'surface'],
  ['buildings_nb', 'buildings_nb'],
  ['dwellings_nb', 'dwellings_nb'],
];

export function formatOverview(
  portfolio: Portfolios.PortfolioOverview
): Omit<ObjectListItemType, 'icon'> {
  const { id, label: title, type: subtitle } = portfolio;
  const description = portfolioOverviewDescriptionTuple
    .map(([key, label, suffix, formater]) => {
      const value = verifyNumber(portfolio[key]);
      if (!value) return;

      const diff = verifyNumber(portfolio[(key + '_var') as typeof key]) || null;
      return {
        label,
        trends: [
          {
            value: value ? formater(value) : null,
            diff,
            suffix,
          },
        ],
      };
    })
    .filter(Boolean) as ObjectListItemType['description'];

  const legends = portfolioOverviewLegendTuple
    .map(([key, type]) => {
      const value = verifyNumber(portfolio[key]);
      if (!value) return;
      return { type, value };
    })
    .filter(Boolean) as ObjectListItemType['legends'];

  return {
    id,
    type: 'portfolios',
    title,
    subtitle,
    description,
    legends,
  };
}

export function formatOverviews({
  portfolios,
}: {
  portfolios: Array<Portfolios.PortfolioOverview>;
}): Array<Omit<ObjectListItemType, 'icon'>> {
  return portfolios.map(formatOverview);
}

export function formatTimeSeries<K extends string>(
  series?: Array<Partial<Record<K, number | null>> & { date: string }>,
  percentageKey?: string[],
  isStep?: boolean
) {
  if (!series || !(series || []).length) return undefined;
  const clearedSeries = series.filter(Boolean);
  const orderedSeries =
    clearedSeries.sort((dataA, dataB) => {
      const dateA = new Date(dataA.date);
      const dateB = new Date(dataB.date);
      if (dateA < dateB) return -1;
      if (dateA > dateB) return 1;
      return 0;
    }) || [];
  const duplicated: Array<
    Partial<Record<K, number | null>> & { date: string; invisible?: boolean }
  > = isStep
    ? orderedSeries.flatMap((element, index) =>
        index < clearedSeries.length - 1
          ? [element, { ...element, date: clearedSeries[index + 1].date, invisible: true }]
          : [element]
      )
    : orderedSeries;
  const keys = Object.keys(duplicated[0]).filter((key) => key !== 'date' && key !== 'invisible');
  type MultiLineDataType = Record<
    K,
    Array<{ group: string; date: string; value: number; invisible?: boolean }>
  >;

  const initFormated = keys.reduce((acc, key) => ({ ...acc, [key]: [] }), {} as MultiLineDataType);

  const formated = duplicated.reduce((acc, dataPoint, index) => {
    const { date, invisible = false, ...rest } = dataPoint;
    const nextDataPoint =
      index < duplicated.length - 1 ? duplicated[index + 1] : { date: '', invisible: true };
    return keys.reduce((innerAcc, key) => {
      const currentVal = rest[key as keyof typeof rest];
      if (
        currentVal === null ||
        (isStep && !nextDataPoint[key as keyof typeof nextDataPoint] && date === nextDataPoint.date)
      ) {
        return innerAcc;
      }
      const current = {
        group: key,
        date: date,
        value: percentageKey?.includes(key) && currentVal ? currentVal * 100 : currentVal,
        invisible,
      };
      return {
        ...innerAcc,
        [key]: [...innerAcc[key as keyof typeof innerAcc], current],
      };
    }, acc);
  }, initFormated);
  return formated;
}
